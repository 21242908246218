/*! modl.js */

const animateHTML = function () {
  let elems;
  let windowHeight;

  function init() {
    elems = document.querySelectorAll('.hidden-fade');
    windowHeight = window.innerHeight;
    addEventHandlers();
    checkPosition();
  }

  function addEventHandlers() {
    window.addEventListener('scroll', checkPosition);
    window.addEventListener('resize', init);
  }

  function checkPosition() {
    for (let i = 0; i < elems.length; i++) {
      const positionFromTop = elems[i].getBoundingClientRect().top;
      if (positionFromTop - windowHeight <= -100) {
        elems[i].classList.add('fade-in-element');
        elems[i].classList.remove('hidden-fade');
      }
    }
  }

  return {
    init: init
  };
};

animateHTML().init();